<template>
  <div>
    <hero-bar>
      <span class="capitalize"> {{ title }} </span>
      <router-link slot="right" to="/messages" class="button">
        {{ $getTranslation("general.views.messaging") }}
      </router-link>
    </hero-bar>

    <section class="section is-main-section">
      <tiles v-if="item !== null">
        <card-component :title="item.subject" icon="email" class="tile is-child">
          <b-field :label="$getTranslation('messaging.from')">
            <b-input :value="item.sender.name + ' (' +  item.sender.email + ')'" custom-class="is-static" readonly />
          </b-field>
          <b-field :label="$getTranslation('form.general.sent')" class="capitalize">
            <b-input :value="item.created" custom-class="is-static" readonly />
          </b-field>
          <hr>
          <b-field :label="$getTranslation('form.type.label')" class="capitalize">
            <b-input :value="item.media" custom-class="is-static" readonly />
          </b-field>
          <hr />
          <b-field :label="$getTranslation('form.subject.label')">
            <b-input :value="item.subject" custom-class="is-static" readonly />
          </b-field>
          <hr />
          <b-field :label="$getTranslation('form.message.label')">
            <b-input :value="item.message" custom-class="is-static" readonly />
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { ApiMessaging, ApiUsers } from '@/api-routes.js'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'
import Tiles from '@/components/Tiles'

export default {
  name: 'app-view-message',
  props: ['slug'],
  mixins: [FormTemplateMixin],
  components: {
    Tiles
  },
  data () {
    return {
      title: this.$getTranslation('form.general.view') + ' ' + this.$getTranslation('form.message.label'),
      endpoint: ApiMessaging,

      type: 'is-static'
    }
  },

  computed: {
    ...mapGetters([
      'item',
    ]),

  },

  methods: {
    ...mapActions([
      'fetchItem'
    ]),
  },

  mounted () {
    // console.log(this.slug)
    let payload = {
      endpoint: this.endpoint + '/show/' + this.slug
    }
    this.fetchItem(payload)
  }
}
</script>

